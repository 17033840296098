@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Outfit:wght@100..900&family=Prata&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: Outfit;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
};

.prata-regular {
    font-family: "Prata", serif;
    font-weight: 400;
    font-style: normal;
};

.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
};

.SelectedOne:active hr,
.SelectedOne:hover hr,
.SelectedOne:focus hr {
    opacity: 1;
    transition: opacity 0.2s ease;
};


@layer components {
    .sub-menu {
        @apply absolute p-[15px] rounded-b-[6px] rounded-tr-[6px] origin-[50%_-170px] text-black backdrop-blur bg-white shadow-2xl z-50;
    };
};

.ourProduct-slide .slick-slide {
    padding: 20px;
}
;
@media (max-width: 600px) {
    .ourProduct-slide .slick-slide {
        padding: 10px;
    };
};

  .mySwiper .swiper-pagination-bullet {
    background-color: #000;
    
  };
  
  .mySwiper .swiper-pagination-bullet-active {
    background-color: #ffc609;
  };
  
  .loading {
    background-color: #6c757d !important;
    cursor: not-allowed;
  }

  .update-btn.loading {
    opacity: 0.6;
    pointer-events: none;
  };

  input[type="number"] {
    appearance: textfield;
  };

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield; 
  };
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  };

.custom-quill-editor .ql-toolbar {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
};

@media (min-width: 621px) {
  .createAcc-button::after {
    content: 'Create account';
  }
};

@media (max-width: 620px) {
  .createAcc-button::after {
    content: 'Create';
  }
};

.customScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
};

.customScroll::-webkit-scrollbar-track {
  /* background: red; */
  background: #f0f0f0;
  border-radius: 10px;
};

.customScroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
};

.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
};

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid orange;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
};

input[type="radio"]:checked {
  background-color: orange;
};

input[type="radio"]:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
};

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #d99389;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #d99389;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #d99389;
  border-radius: 50%;
  cursor: pointer;
}


/* anniversaryimages style */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-img {
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
}

.close, .arrow {
  position: absolute;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.close {
  top: 20px;
  right: 30px;
}

.arrow.left {
  left: 20px;
}

.arrow.right {
  right: 20px;
}

