.skeleton {
    border-radius: 6px;
    display: inline-block;
    line-height: 100%;
    width: 100%;
    background-color: #fff;
    background: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 10%,
        #f6f7f8 20%,
        #f6f7f8 100%
      );
    background-size: 200% 100%;
    animation: placeholderShimmer 1.5s linear infinite;
}

@keyframes placeholderShimmer {
    0% {
        background-position: 50% 0;
    }
    100% { 
        background-position: -150% 0;
    }
}

.skeleton.circle {
    border-radius: 50%;
}